<template lang="pug">
    .share-photo(itemscope itemtype="http://schema.org/ImageObject")
        loading(v-if="isLoading")
        template(v-else)
            //._head
            //    router-link(to="/")._link
            //        svg-icon(icon-name="logo-icon")._logo
            //    | Изображение создано в Merlinface.com
            ._content
                h1._title(itemprop="name") {{ message.seo.title }}
                image-skeleton(:src="message.small_image ? message.small_image : message.image")._image
                ._description
                    p Генерируй изображения по тексту или создай фотографию без фотографа за секунды.
                    p Регистрируйся в Merlinface
                    ui-button(text="Создать аккаунт" @click.native="$router.push('/welcome/login')")._btn
            ._more(v-if="photos.length")
                ._more-title Еще рисунки и фото
                ._more-list
                    a(:href="`/share/photo/${image.id}`" v-for="(image, index) in photos" :key="index" )._more-link
                        image-skeleton(:src="image.small_image ? image.small_image : image.image" :alt="`${image.prompt ? `Сгенерировано по запросу: ${image.prompt}` : 'Фото сгенерировано нейросетью'}`")._more-image
                ui-button(variant="rounded" text="Сгенерировать фото" @click.native="$router.push('/welcome/login')")._btn
            ._photo-description(itemprop="description") {{ message.seo.description }}
        router-link(to="/")._head.fixed
            ._link
                svg-icon(icon-name="logo-icon")._logo
            | Изображение создано в Merlinface.com
</template>
<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";
import gql from "graphql-tag";
import getMidjourneyDataQuery from "@/graphql/queries/getMidjourneyData.query.graphql";
import getAIImagesQuery from "@/graphql/queries/getAIImages.query.graphql";
import ImageSkeleton from "@/components/image-skeleton/image-skeleton.vue";

export default {
    components: { ImageSkeleton, UiButton },
    data() {
        return {
            isLoading: true,
            message: {
                prompt: "",
                image: "",
                seo: {
                    title: "",
                    description: "",
                    categories: "",
                    alt: "",
                    keywords: "",
                },
            },
            photos: [],
        };
    },
    computed: {
        messageId: (state) => state.$route.params.id,
    },
    metaInfo() {
        return {
            title: this.message.seo.title + " | Merlinface.com",
            meta: [
                {
                    name: "description",
                    content: this.message.seo.description,
                    vmid: "description",
                },
                {
                    name: "keywords",
                    content: this.message.seo.keywords,
                    vmid: "keywords",
                },
                {
                    name: "og:description",
                    content: this.message.seo.description,
                    vmid: "og_description",
                },
                {
                    name: "og:image",
                    content: this.message.image,
                    vmid: "og_image",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: document.location.origin + this.$route.path,
                },
                {
                    rel: "amphtml",
                    href: document.location.origin + this.$route.path + "/amp",
                }]
        };
    },
    mounted() {
        this.getMidjourneyData();
        this.getMidjourneyImages();
    },
    methods: {
        async getMidjourneyData() {
            await this.$apollo
                .query({
                    query: gql(getMidjourneyDataQuery),
                    variables: {
                        id: +this.messageId,
                    },
                    client: "chatClient",
                })
                .then((r) => {
                    this.message = r.data.getMidjourneyData;
                    this.isLoading = false;
                });
        },
        async getMidjourneyImages() {
            await this.$apollo
                .query({
                    query: gql(getAIImagesQuery),
                    client: "chatClient",
                    variables: {
                        count: 9,
                        type: "generated-by-midjorney",
                    },
                })
                .then((r) => {
                    this.photos = r.data.getAIImages;
                });
        },
    },
};
</script>

<style lang="scss">
.share-photo {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100dvh;
    background: #141414;

    &__head {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        padding: 20px;
        @media (max-width: 380px) {
            font-size: 14px;
        }

        &.fixed {
            background: #222;
            justify-content: center;
            width: 100%;
            padding: 10px 0;
            z-index: 10;
            margin-top: auto;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__link {
        color: #222;
    }

    &__logo {
        width: 30px;
        height: 30px;
        border-radius: 8px;
        fill: $gold;
        margin-right: 10px;
    }

    &__title {
        font-size: 18px;
        text-align: center;
        color: #fff;
        max-width: 500px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 50px;
        border-radius: 10px;
        background: #222;
        margin-top: 10px;
        @media (max-width: 480px) {
            padding: 20px 5px;
        }
    }

    &__image {
        width: 100%;
        max-width: 400px;
        margin: 15px 0;
        border-radius: 10px;
        @media (max-width: 515px) {
        }
    }

    &__description {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        max-width: 500px;

        p {
            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    &__photo-description {
        font-size: 14px;
        line-height: 1.5;
        color: #fff;
        margin-top: 40px;
        max-width: 650px;
        margin-bottom: 40px;
        text-align: center;
    }

    &__btn {
        margin-top: 30px;
        border-radius: 5px;
        font-weight: bold;
        color: #141414;
    }

    &__more {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        width: 100%;
    }

    &__more-title {
        width: 100%;
        text-align: center;
        font-size: 21px;
        color: #fff;
    }

    &__more-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
        gap: 20px;
        width: 100%;
        max-width: 700px;
    }

    &__more-link {
        border-radius: 20px;
        width: 25%;
        object-fit: cover;
        margin: 0;

        img {
            border-radius: 5px;
        }

        &:nth-child(1),
        &:nth-child(7) {
            margin-left: 10%;
        }
    }
}
</style>
